import { createAggregatedClient } from "@aws-sdk/smithy-client";
import { ChimeSDKMessagingClient } from "./ChimeSDKMessagingClient";
import { AssociateChannelFlowCommand, } from "./commands/AssociateChannelFlowCommand";
import { BatchCreateChannelMembershipCommand, } from "./commands/BatchCreateChannelMembershipCommand";
import { ChannelFlowCallbackCommand, } from "./commands/ChannelFlowCallbackCommand";
import { CreateChannelBanCommand, } from "./commands/CreateChannelBanCommand";
import { CreateChannelCommand, } from "./commands/CreateChannelCommand";
import { CreateChannelFlowCommand, } from "./commands/CreateChannelFlowCommand";
import { CreateChannelMembershipCommand, } from "./commands/CreateChannelMembershipCommand";
import { CreateChannelModeratorCommand, } from "./commands/CreateChannelModeratorCommand";
import { DeleteChannelBanCommand, } from "./commands/DeleteChannelBanCommand";
import { DeleteChannelCommand, } from "./commands/DeleteChannelCommand";
import { DeleteChannelFlowCommand, } from "./commands/DeleteChannelFlowCommand";
import { DeleteChannelMembershipCommand, } from "./commands/DeleteChannelMembershipCommand";
import { DeleteChannelMessageCommand, } from "./commands/DeleteChannelMessageCommand";
import { DeleteChannelModeratorCommand, } from "./commands/DeleteChannelModeratorCommand";
import { DeleteMessagingStreamingConfigurationsCommand, } from "./commands/DeleteMessagingStreamingConfigurationsCommand";
import { DescribeChannelBanCommand, } from "./commands/DescribeChannelBanCommand";
import { DescribeChannelCommand, } from "./commands/DescribeChannelCommand";
import { DescribeChannelFlowCommand, } from "./commands/DescribeChannelFlowCommand";
import { DescribeChannelMembershipCommand, } from "./commands/DescribeChannelMembershipCommand";
import { DescribeChannelMembershipForAppInstanceUserCommand, } from "./commands/DescribeChannelMembershipForAppInstanceUserCommand";
import { DescribeChannelModeratedByAppInstanceUserCommand, } from "./commands/DescribeChannelModeratedByAppInstanceUserCommand";
import { DescribeChannelModeratorCommand, } from "./commands/DescribeChannelModeratorCommand";
import { DisassociateChannelFlowCommand, } from "./commands/DisassociateChannelFlowCommand";
import { GetChannelMembershipPreferencesCommand, } from "./commands/GetChannelMembershipPreferencesCommand";
import { GetChannelMessageCommand, } from "./commands/GetChannelMessageCommand";
import { GetChannelMessageStatusCommand, } from "./commands/GetChannelMessageStatusCommand";
import { GetMessagingSessionEndpointCommand, } from "./commands/GetMessagingSessionEndpointCommand";
import { GetMessagingStreamingConfigurationsCommand, } from "./commands/GetMessagingStreamingConfigurationsCommand";
import { ListChannelBansCommand, } from "./commands/ListChannelBansCommand";
import { ListChannelFlowsCommand, } from "./commands/ListChannelFlowsCommand";
import { ListChannelMembershipsCommand, } from "./commands/ListChannelMembershipsCommand";
import { ListChannelMembershipsForAppInstanceUserCommand, } from "./commands/ListChannelMembershipsForAppInstanceUserCommand";
import { ListChannelMessagesCommand, } from "./commands/ListChannelMessagesCommand";
import { ListChannelModeratorsCommand, } from "./commands/ListChannelModeratorsCommand";
import { ListChannelsAssociatedWithChannelFlowCommand, } from "./commands/ListChannelsAssociatedWithChannelFlowCommand";
import { ListChannelsCommand, } from "./commands/ListChannelsCommand";
import { ListChannelsModeratedByAppInstanceUserCommand, } from "./commands/ListChannelsModeratedByAppInstanceUserCommand";
import { ListSubChannelsCommand, } from "./commands/ListSubChannelsCommand";
import { ListTagsForResourceCommand, } from "./commands/ListTagsForResourceCommand";
import { PutChannelExpirationSettingsCommand, } from "./commands/PutChannelExpirationSettingsCommand";
import { PutChannelMembershipPreferencesCommand, } from "./commands/PutChannelMembershipPreferencesCommand";
import { PutMessagingStreamingConfigurationsCommand, } from "./commands/PutMessagingStreamingConfigurationsCommand";
import { RedactChannelMessageCommand, } from "./commands/RedactChannelMessageCommand";
import { SearchChannelsCommand, } from "./commands/SearchChannelsCommand";
import { SendChannelMessageCommand, } from "./commands/SendChannelMessageCommand";
import { TagResourceCommand } from "./commands/TagResourceCommand";
import { UntagResourceCommand, } from "./commands/UntagResourceCommand";
import { UpdateChannelCommand, } from "./commands/UpdateChannelCommand";
import { UpdateChannelFlowCommand, } from "./commands/UpdateChannelFlowCommand";
import { UpdateChannelMessageCommand, } from "./commands/UpdateChannelMessageCommand";
import { UpdateChannelReadMarkerCommand, } from "./commands/UpdateChannelReadMarkerCommand";
const commands = {
    AssociateChannelFlowCommand,
    BatchCreateChannelMembershipCommand,
    ChannelFlowCallbackCommand,
    CreateChannelCommand,
    CreateChannelBanCommand,
    CreateChannelFlowCommand,
    CreateChannelMembershipCommand,
    CreateChannelModeratorCommand,
    DeleteChannelCommand,
    DeleteChannelBanCommand,
    DeleteChannelFlowCommand,
    DeleteChannelMembershipCommand,
    DeleteChannelMessageCommand,
    DeleteChannelModeratorCommand,
    DeleteMessagingStreamingConfigurationsCommand,
    DescribeChannelCommand,
    DescribeChannelBanCommand,
    DescribeChannelFlowCommand,
    DescribeChannelMembershipCommand,
    DescribeChannelMembershipForAppInstanceUserCommand,
    DescribeChannelModeratedByAppInstanceUserCommand,
    DescribeChannelModeratorCommand,
    DisassociateChannelFlowCommand,
    GetChannelMembershipPreferencesCommand,
    GetChannelMessageCommand,
    GetChannelMessageStatusCommand,
    GetMessagingSessionEndpointCommand,
    GetMessagingStreamingConfigurationsCommand,
    ListChannelBansCommand,
    ListChannelFlowsCommand,
    ListChannelMembershipsCommand,
    ListChannelMembershipsForAppInstanceUserCommand,
    ListChannelMessagesCommand,
    ListChannelModeratorsCommand,
    ListChannelsCommand,
    ListChannelsAssociatedWithChannelFlowCommand,
    ListChannelsModeratedByAppInstanceUserCommand,
    ListSubChannelsCommand,
    ListTagsForResourceCommand,
    PutChannelExpirationSettingsCommand,
    PutChannelMembershipPreferencesCommand,
    PutMessagingStreamingConfigurationsCommand,
    RedactChannelMessageCommand,
    SearchChannelsCommand,
    SendChannelMessageCommand,
    TagResourceCommand,
    UntagResourceCommand,
    UpdateChannelCommand,
    UpdateChannelFlowCommand,
    UpdateChannelMessageCommand,
    UpdateChannelReadMarkerCommand,
};
export class ChimeSDKMessaging extends ChimeSDKMessagingClient {
}
createAggregatedClient(commands, ChimeSDKMessaging);
